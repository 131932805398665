import _ from 'lodash';
import extractDomain from './utils/extractDomain';
import { addClassList } from './utils/addClassList';
import { appendToParent, prependChild } from './utils/appendToParent';
import { createElement } from './utils/createElement';
import { getAttributes } from './utils/getAttributes';
import getFirstSiblingWithClass from './utils/getFirstSiblingWithClass';
import loaderImg from '../src/assets/loader.gif';

class BuyerCall {
  constructor({
    classList = '',
    buttonImgurl = '',
    subTitle = '',
    colorPrimary,
    colorSecondary,
    infoSectionBackground,
    url = 'http://localhost:3000/',
    title = 'Launch Button',
    parentDiv,
    isModal = false,
    buyyerElement,
    buyerCallInputs = {},
    customerDetails = {},
    innerHTML = 'widget button',
    createIframe = true,
  } = {}) {
    this.buttonImgurl = buttonImgurl;
    this.innerHTML = innerHTML;
    this.customerDetails = customerDetails;
    this.colorPrimary = colorPrimary;
    this.colorSecondary = colorSecondary;
    this.infoSectionBackground = infoSectionBackground;
    this.isModal = isModal;
    this.url = url;
    this.classes = classList;
    this.lauchButton = buyyerElement;
    this.buttonTitle = title;
    this.buttonSubTitle = subTitle;
    this.open = false;
    this.parentDiv = parentDiv;
    this.buyerCallInputs = buyerCallInputs;
    this.titleContainer = null;
    this.buttonFallBacktitleContainer = null;
    this.iWindow = null;
    this.createIframe = createIframe;
    this.iframeContainer = null;
    this.imgContainer = null;
    this.initialize();
  }
  // Initializing
  initialize() {
    if (this.createIframe) {
      // Creating Container for Iframe
      this.iframeContainer = createElement('div');
      addClassList(this.iframeContainer, [
        'cta__buyerCall__modalLaunch__hidden',
        'cta__buyerCall__modalLaunch__modal_container',
      ]);

      // Creating Iframe
      this.iframe = createElement('iframe');
      addClassList(
        this.iframe,
        'cta__buyerCall__modalLaunch__modal_container_iframe'
      );
      this.iframe.src = this.url; //url of the widget modal
      this.iframe.style.opacity = 0;
      this.iframe.setAttribute('allowTransparency', 'true'); // Correct attribute name and value
      this.iframe.setAttribute('background', 'transparent');
    }
    // Adding additional classes to button
    _.forEach(this.classes ? this.classes.split(' ') : [], (item) =>
      addClassList(this.lauchButton, item)
    );

    // If its a button trigering modal
    if (this.isModal === 'true') {
      if (this.createIframe) {
        document.body.append(this.iframeContainer);
      }
      // Disbale the button initially
      this.lauchButton.disabled = true;

      // Add additional classes to button
      addClassList(
        this.lauchButton,
        'cta__buyerCall__modalLaunch_buttonLaunch'
      );

      // Create fallback container for title to be placed inside button when iframe is not yet loaded
      this.buttonFallBacktitleContainer = createElement('div');
      addClassList(
        this.buttonFallBacktitleContainer,
        'cta__buyerCall_titlecontainer'
      );
      this.fallbacktitle = createElement('span');
      addClassList(this.fallbacktitle, 'loader-g');
      this.fallbacktitle.src = loaderImg;
      appendToParent(this.buttonFallBacktitleContainer, this.fallbacktitle);
      appendToParent(this.lauchButton, this.buttonFallBacktitleContainer);

      // Add Launch button to DOM to the parent element
      appendToParent(this.parentDiv, this.lauchButton);

      // Add listner to button onclick
      this.lauchButton.addEventListener('click', this.modalOpen.bind(this));
    } else {
      this.iframeContainer.classList.remove(
        'cta__buyerCall__modalLaunch__modal_container'
      );

      addClassList(this.iframeContainer, 'cta__buyerCall__modalLaunch__screen');
      addClassList(
        this.lauchButton,
        'cta__buyerCall__modalLaunch_isModal_false'
      );

      appendToParent(this.lauchButton, this.iframeContainer);
    
      this.iframe.addEventListener('load', () => {
        // Code to be executed after the iframe has loaded
        this.iframe.style.opacity = 1;
        this.iframeContainer.classList.remove(
          'cta__buyerCall__modalLaunch__hidden'
        );
      });
    }
   
    if (this.createIframe) {
      this.iframe.addEventListener('load', () => {
        const lauchButtonsTitleContainers = document.querySelectorAll(
          '.cta__buyerCall_titlecontainer'
        );
        if (this.isModal === 'false') {
          this.iWindow = this.iframe.contentWindow;
          this.modalOpen.call(this);
        }
        if (
          this.isModal === 'true' &&
          lauchButtonsTitleContainers instanceof NodeList &&
          lauchButtonsTitleContainers.length > 0
        ) {
          lauchButtonsTitleContainers.forEach((lauchButtonsTitleContainer) => {
            // this.iWindow = this.iframe.contentWindow;
            const parentNode = lauchButtonsTitleContainer.parentNode; // Which is the button
            const childElementToRemove = parentNode.childNodes[0]; // Replace 'childClass' with the class name of the element you want to remove
            // Check if the child element was found
            if (childElementToRemove) {
              // Remove the child element from the parent
              parentNode.removeChild(childElementToRemove);
            }

            const wrapperElement = getFirstSiblingWithClass(
              parentNode,
              'cta__buyerCall__modalLaunch'
            );
            let buttonTitle = getAttributes(wrapperElement, 'title');
            let buttonsubTitle = getAttributes(wrapperElement, 'subTitle');
            let buttonImageUrl = getAttributes(
              wrapperElement,
              'button-img-url'
            );
            // Create container for title to be placed inside button
            this.titleContainer = createElement('div');
            addClassList(this.titleContainer, 'cta__buyerCall_titlecontainer');
            this.title = createElement('h4');
            this.title.innerHTML = buttonTitle;
            appendToParent(this.titleContainer, this.title);
            this.subTitle = createElement('h6');
            this.subTitle.innerHTML = buttonsubTitle;
            appendToParent(this.titleContainer, this.subTitle);
            appendToParent(parentNode, this.titleContainer);
            // Add image to launch button if any
            if (buttonImageUrl) {
              this.imgContainer = createElement('div');
              addClassList(this.imgContainer, 'cta__buyerCall_imgContainer');
              this.img = createElement('img');
              this.img.src = buttonImageUrl;
              appendToParent(this.imgContainer, this.img);
            }
            appendToParent(parentNode, this.imgContainer);
            parentNode.disabled = false;
          });
        }
      });
    }
    if (
      (this.isModal === 'true' && this.createIframe) ||
      this.isModal === 'false'
    ) {
      // Appending Iframe to Iframe Container
      appendToParent(this.iframeContainer, this.iframe);
    }

    // Listening to child iframe communications
    window.addEventListener('message', (event) => {
      if (event.origin !== extractDomain(this.url).replace(/\/+$/, '')) {
        // Ensure the message is from a trusted source (e.g., the child iframe's origin)
        return;
      }
      const receivedMessage = event.data;
      const iframeContainer = document.querySelector(
        '.cta__buyerCall__modalLaunch__modal_container'
      );
      if (receivedMessage.btnClose === true) {
        addClassList(iframeContainer, 'cta__buyerCall__modalLaunch__hidden');
      }
    });
  }
  //function for open the modal
  modalOpen() {
    if (this.isModal === 'true') {
      const iframeContainer = document.querySelector(
        '.cta__buyerCall__modalLaunch__modal_container'
      );
      const isOpen = !iframeContainer.classList.contains(
        'cta__buyerCall__modalLaunch__hidden'
      );
      const childIframe = document.getElementsByClassName(
        'cta__buyerCall__modalLaunch__modal_container_iframe'
      )[0];
      const inputData = {
        isModal: this.isModal,
        customerDetails: this.customerDetails,
        infoSectionBackground: this.infoSectionBackground,
        colorPrimary: this.colorPrimary,
        colorSecondary: this.colorSecondary,
        fromOrigin: true,
        ...this.buyerCallInputs,
      };

      const urlFormatted = this.url.replace(/\/+$/, '');
      if (!isOpen) {
        // childIframe.contentWindow.postMessage(inputData, window.location.origin);
        childIframe.contentWindow.postMessage(inputData, urlFormatted);
        iframeContainer.classList.remove('cta__buyerCall__modalLaunch__hidden');
        childIframe.style.opacity = 1;
      } else {
        addClassList(iframeContainer, 'cta__buyerCall__modalLaunch__hidden');
        childIframe.style.opacity = 0;
      }
    } else {
      const iframeContainer = document.querySelector(
        '.cta__buyerCall__modalLaunch__screen'
      );
      const isOpen = false;
      const childIframe = document.getElementsByClassName(
        'cta__buyerCall__modalLaunch__modal_container_iframe'
      )[0];
      const inputData = {
        isModal: this.isModal,
        customerDetails: this.customerDetails,
        infoSectionBackground: this.infoSectionBackground,
        colorPrimary: this.colorPrimary,
        colorSecondary: this.colorSecondary,
        fromOrigin: true,
        ...this.buyerCallInputs,
      };

      const urlFormatted = this.url.replace(/\/+$/, '');
      if (!isOpen) {
        // childIframe.contentWindow.postMessage(inputData, window.location.origin);
        childIframe.contentWindow.postMessage(inputData, urlFormatted);
        // iframeContainer.classList.remove('cta__buyerCall__modalLaunch__hidden');
        childIframe.style.opacity = 1;
      }
    }
  }
}

function createStyles() {
  const styleTag = createElement('style');
  document.head.appendChild(styleTag);
  styleTag.innerHTML = `
    .cta__buyerCall__modalLaunch_buttonLaunch {
      width:100%;
      min-height:70px;
      height:100%;
      background-color: #334049;
      border-radius: 8px;
      border-style: none;
      box-sizing: border-box;
      color: #FFFFFF;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      list-style: none;
      margin: 0;
      outline: none;
      padding: 10px 16px;
      position: relative;
      text-align: center;
      text-decoration: none;
      transition: color 100ms;
      vertical-align: baseline;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;  
    }
    .cta__buyerCall__modalLaunch_isModal_false{
      min-height:900px;
      display: flex;
    }
    @media (max-width: 576px) {
      .cta__buyerCall__modalLaunch_isModal_false{
        min-height:2050px;
        display: flex;
      }
    }
    .cta__buyerCall__modalLaunch_buttonLaunch:hover,
    .cta__buyerCall__modalLaunch_buttonLaunch:focus {
        background-color: #29333a;
    }
   .cta__buyerCall_imgContainer {
    width: 30%;
    margin: auto;
    display: block;
   }
   .cta__buyerCall_titlecontainer {
    width: 100%;
    height: 100%;
    margin: auto;
    display: block;
    padding-right: 5%;
    justify-content: center;
    align-items: normal;
    text-align: left;
    flex-direction: column;
   }
   .cta__buyerCall_titlecontainer > h4 {
    margin: 0;
    padding: 0;
    font-weight: 800;
   }
   .cta__buyerCall_titlecontainer > h6 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
   }
   .loader-g {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    display: inline-block;
  }
  .loader-g::before , .loader-g::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 3px solid #B2B2B2;
    // animation: prixClipFix 2s linear infinite ;
    animation: prixClipFix 2s  infinite ;
  }
  .loader-g::after{
    border-color: #fff;
    animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
    inset: 6px;
  }
  
  @keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
  }
  
  @keyframes prixClipFix {
      0% {clip-path:polygon(50% 50%,50% 0,100% 0,100% 100%,0 100%,0 0)}
      100% {clip-path:polygon(50% 50%,50% 0,100% 0,100% 100%,0 100%,0 0)}
  }
   .cta__buyerCall_imgContainer > img{
    width: 100%;
    height: 100%;
    padding-horizontal: 5%;
   }

    .cta__buyerCall__modalLaunch__hidden {
      display: none;
    }
    .cta__buyerCall__modalLaunch__screen {
      width: 100%;
    }
    .cta__buyerCall__modalLaunch__button_container {
      background-color: transparent;
      width: "100%";
      height: "100%";
    }

    .cta__buyerCall__modalLaunch__modal_container {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 3000;
      display: block;
      background-color: transparent;
      width: 100%;
      height: 100%;
    
    }
    .cta__buyerCall__modalLaunch__modal_container.cta__buyerCall__modalLaunch__hidden {
      display: none;
    }

    .cta__buyerCall__modalLaunch__modal_container_iframe {
      background-color: transparent;
      width: 100%;
      height: 100%;
      border: 0;
    }
    .cta__buyerCall__modalLaunch__modal_container_iframe .modal {
      display: block !important;
      opacity: 1 !important;
      background-color: transparent !important;
    }
    @media (max-width: 992px) {
      .cta__buyerCall_imgContainer > img {
        width: 100%;
      }
    }
 `;
}

// Function to handle the network request completion
function processCompletedNetworkRequest() {
  // Page is fully loaded, and the network requests are also completed.

  const buyyerCallWidgetDiv = document.querySelectorAll(
    '.cta__buyerCall__modalLaunch'
  );
  let formIndex = 0;
  buyyerCallWidgetDiv.forEach((elem, inx) => {
    if (elem.tagName === 'DIV') {
      const isModal = elem.getAttribute('isModal');
      const buyyerCallWidgetButton = document.createElement('button');
      const parentDiv = elem.parentNode;
      if (isModal === 'true') {
        elem.style.display = 'none';
        new BuyerCall({
          buyyerElement: buyyerCallWidgetButton,
          isModal,
          parentDiv,
          classList: getAttributes(elem, 'classList'),
          url: getAttributes(elem, 'url'),
          title: getAttributes(elem, 'title'),
          buyerCallInputs: getAttributes(elem, 'buyerCallInputs')
            ? JSON.parse(getAttributes(elem, 'buyerCallInputs'))
            : {},
          subTitle: getAttributes(elem, 'subTitle'),
          innerHTML: elem.innerHTML,
          colorPrimary: getAttributes(elem, 'colorPrimary'),
          colorSecondary: getAttributes(elem, 'colorSecondary'),
          buttonImgurl: getAttributes(elem, 'button-img-url'),
          customerDetails: getAttributes(elem, 'customerDetails')
            ? JSON.parse(getAttributes(elem, 'customerDetails'))
            : {},
          infoSectionBackground: getAttributes(elem, 'infoSectionBackground'),
          createIframe: inx === 0,
        });
      } else {
        //for single form
        formIndex++;
        if (formIndex === 1) {
          new BuyerCall({
            buyyerElement: elem,
            isModal,
            parentDiv,
            classList: getAttributes(elem, 'classList'),
            url: getAttributes(elem, 'url'),
            colorPrimary: getAttributes(elem, 'colorPrimary'),
            colorSecondary: getAttributes(elem, 'colorSecondary'),
            infoSectionBackground: getAttributes(elem, 'infoSectionBackground'),
            buyerCallInputs: getAttributes(elem, 'buyerCallInputs')
              ? JSON.parse(getAttributes(elem, 'buyerCallInputs'))
              : {},
            customerDetails: getAttributes(elem, 'customerDetails')
              ? JSON.parse(getAttributes(elem, 'customerDetails'))
              : {},
          });
        }
      }
      createStyles();
    }
  });
}

// Define the class name of the div you want to watch for
const targetClassName = 'cta__buyerCall__modalLaunch';
// Flag to track whether the event has already fired
let bc_widget_eventFired = false;

// Create a function to handle the appearance of the target div
function handleTargetBCWidgetDivAppearance() {
  if (!bc_widget_eventFired) {
    const targetDiv = document.querySelector(`.${targetClassName}`);
    if (targetDiv) {
      // The target div has appeared in the DOM
      processCompletedNetworkRequest();

      // You can perform any actions here when the div appears

      // Set the flag to true to indicate that the event has fired
      bc_widget_eventFired = true;
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  // Create a mutation observer
  const observer = new MutationObserver(handleTargetBCWidgetDivAppearance);

  // Start observing the document for changes
  observer.observe(document, {
    childList: true, // Watch for changes in the child nodes of the document
    subtree: true, // Watch for changes in the entire subtree of the document
  });

  // You can also call handleTargetBCWidgetDivAppearance() once immediately to check if the div is already present on page load
  handleTargetBCWidgetDivAppearance();
});
